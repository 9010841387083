<template>
  <div class="company_profile main_padding">

    <div class="title_outer w1400">
      <div>
        <h2 class="h1 txt-theme">
          <span>OUR</span>
          <span class="txt-bold">&nbsp;LOCATIONS</span>
        </h2>
        <h5 class="sub_title txt-bold txt-white">我們的交通位置</h5>
      </div>
      <div class="controls">
        <div class="control to_left">
          <img src="@/assets/img/public/03_button/02_Button_2.svg" alt="">
        </div>
        <div class="control to_right">
          <img src="@/assets/img/public/03_button/02_Button_2.svg" alt="">
        </div>
      </div>
    </div>

    <div class="map_outer w1400">
      <div class="map">
        <img src="@/assets/img/about/company_profile/02_map.svg" class="map_main" alt="">
        <div
          class="map_btn japan"
          :class="{active: (mapNowIndex === 0)}"
          @click="locBoxTo(0)">
            <img
              src="@/assets/img/about/company_profile/02_map_Japan.svg"
              alt="">
            <div class="circle"></div>
        </div>
        <div
          class="map_btn taipei"
          :class="{active: (mapNowIndex === 1)}"
          @click="locBoxTo(1)">
            <img
              src="@/assets/img/about/company_profile/02_map_Taipei.svg"
              alt="">
            <div class="circle"></div>
        </div>
        <div
          class="map_btn vietnam"
          :class="{active: (mapNowIndex === 2)}"
          @click="locBoxTo(2)">
            <img
              src="@/assets/img/about/company_profile/02_map_Vietnam.svg"
              alt="">
            <div class="circle"></div>
        </div>
        <div
          class="map_btn myanmar"
          :class="{active: (mapNowIndex === 3)}"
          @click="locBoxTo(3)">
            <img
              src="@/assets/img/about/company_profile/02_map_Myanmar.svg"
              alt="">
            <div class="circle"></div>
        </div>
      </div>

      <swiper
        class="loc_box"
        ref="loc_box"
        :options="swiperOption"
        @transitionStart="() => {
          mapNowIndex = locBox.realIndex;
        }">
          <swiper-slide class="loc_item">
            <figure
              class="loc_item_pic"
              :style="{
                backgroundImage:
                `url(${require('@/assets/img/about/company_profile/02_Japan.jpg')})`
              }">
            </figure>
            <h6 class="txt-white">
              日本總社
            </h6>
            <p class="txt-white">
              地址：〒221-8558橫濱市神奈川區新浦島町1-1-25 Technowave 100大樓9樓<br>
              <!-- 電子信箱：sales@kirineng.tw<br> -->
              電話：045-451-7600（代表）/ 045-451-7601（営業部）<br>
              傳真：045-451-7650（代表）/ 045-451-7653（営業部）
            </p>
          </swiper-slide>
          <swiper-slide class="loc_item">
            <figure
              class="loc_item_pic"
              :style="{
                backgroundImage:
                `url(${require('@/assets/img/about/company_profile/02_Taipei.jpg')})`
              }">
            </figure>
            <h6 class="txt-white">
              日商麒麟技術股份有限公司 台北分公司
            </h6>
            <p class="txt-white">
              地址：105404台北市松山區復興北路57號16樓之2<br>
              電子信箱：sales@kirineng.tw<br>
              電話：02-2781-6270<br>
              傳真：02-2773-5106
            </p>
          </swiper-slide>
          <swiper-slide class="loc_item">
            <figure
              class="loc_item_pic"
              :style="{
                backgroundImage:
                `url(${require('@/assets/img/about/company_profile/02_Vietnam.jpg')})`
              }">
            </figure>
            <h6 class="txt-white">
              越南支店
            </h6>
            <p class="txt-white">
              地址：Unit No. 2A, Level 6, Saigon Centre,65 Le Loi Street,
              Ben Nghe Ward, District 1,Ho Chi Minh City, Vietnam<br>
              電話：+84-28-3821-8600
            </p>
          </swiper-slide>
          <swiper-slide class="loc_item">
            <figure
              class="loc_item_pic"
              :style="{
                backgroundImage:
                `url(${require('@/assets/img/about/company_profile/02_Myanmar.jpg')})`
              }">
            </figure>
            <h6 class="txt-white">
              緬甸支店
            </h6>
            <p class="txt-white">
              地址：Room No-401, 4th Floor, Hledan Center,
              (8)Quarter, Hledan, Kamaryut Township,Yangon,Myanmar<br>
              電話：+95-9-899818179
            </p>
          </swiper-slide>
      </swiper>
    </div>

  </div>

</template>

<script>
export default {
  name: 'CompanyProfile',
  components: {
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: false,
        autoplay: false,
        navigation: {
          nextEl: '.title_outer .to_right',
          prevEl: '.title_outer .to_left',
        },
        breakpoints: {
          1023: {
            allowTouchMove: true,
          },
        },
      },
      mapNowIndex: 0,
    };
  },
  computed: {
    locBox() {
      return this.$refs.loc_box.swiper;
    },
  },
  methods: {
    locBoxTo(i) {
      this.locBox.slideToLoop(i);
    },
  },
};
</script>
